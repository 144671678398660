<template lang="pug">
v-menu(v-model="menu"
  min-width="600"
  class="relative"
  :close-on-content-click="false")
  template(#activator="{ on }")
    div.d-flex.align-end.row
      v-text-field(
        v-model="body.date"
        @input="handleChangeModel"
        :label="labelDate"
        max="9999-12-31"
        type="date"
        :class="body.time ? 'col-6': 'col-12'"
        clearable
      ).pt-1
        template(#append)
          v-btn(v-on="on" icon)
            v-icon mdi-calendar

      v-text-field(
        v-if="body.time"
        v-model="body.time"
        @input="handleChangeModel"
        :label="labelTime"
        v-mask="'##:##'"
        :class="body.time ? 'col-6': ''"
      )
        template(#append)
          v-btn(v-on="on" icon)
              v-icon mdi-clock-time-four-outline

  div.d-flex
    v-date-picker(
      v-if="menu"
      v-model="body.date"
      @input="handleChangeModel"
      :locale="lang"
      :max="maxDate"
      :min="minDate"
      first-day-of-week="1"
    )
    v-time-picker(
      v-if="menu"
      v-model="body.time"
      @input="handleChangeModel"
      scrollable
      format="24hr"
      full-width
      @click:minute="menu=false"
    ).py-0
</template>

<script>
import { mapState } from 'vuex'
const initBody = () => (
  { time: null,
    date: null }
)
export default {
  name: 'DateTimePicker',
  props: {
    defaultTime: { type: String, default: '22:00' },
    defaultDate: { type: String, default: new Date().toISOString().split('T')[0] },
    labelDate: { type: String, default: '' },
    labelTime: { type: String, default: '' },
    minDate: { type: String, default: '' },
    maxDate: { type: String, default: '' },
    modelValue: { type: String }
  },
  data () {
    return {
      menu: false,
      body: initBody()
    }
  },
  watch: {
    'body.date' (val) {
      if (val) this.body.time = this.defaultTime
    }
  },
  computed: {
    ...mapState({
      lang: state => state.main.lang
    })
  },
  methods: {
    handleChangeModel () {
      this.$emit('input', `${this.body.date} ${this.body.time}`)
    },
    clearBody () {
      this.body = initBody()
    }
  }
}
</script>
<style lang="sass">
.v-date-picker-title
    &__year
      margin-left: 12px
      font-size: 28px
.v-picker
  &__title
    padding: 0 !important
</style>
